<!-- Finder particular de Búsqueda de APD -->

<template>
  <div class="busqueda_Apd" v-if="schema">
    <v-sheet :elevation="4">

      <!-- Cabecera -->
      <div class="cabecera" v-if="Entorno.header.header">       
        <base_Header 
          :Entorno="Entorno.header"
          @onEvent="event_Header">          
        </base_Header>
      </div>

      <!-- Contenido -->
      <div class="contenedor"> 

        <!-- Filtro de Busqueda -->
        <!-- <div @focusin="focusinFiltro()" :style="Entorno.filtro.style" :class="Entorno.filtro.class"> -->
        <div :style="Entorno.filtro.style" :class="Entorno.filtro.class">
          <div class="columna">
            <div style="display:flex">
              <v-select
                v-bind="$select"
                v-model="schema.ctrls.cia.value"
                :label="schema.ctrls.cia.label"
                style="flex: 0 0 15%"
                :items="itemsCias"
                item-value="id"
                item-text="label">               
              </v-select>
              
              <ctrlfinder
                style="flex: 0 0 32%"
                finderName="muni_F"
                :fn="extraid_Cpo"
                :schema="schema.ctrls.origen">                  
              </ctrlfinder>
                         
              <v-text-field
                style="flex: 0 0 20%"
                v-bind="$input"
                v-model="schema.ctrls.cpo.value"
                :label="schema.ctrls.cpo.label">                                
              </v-text-field>
              
              <compfecha :schema="schema.ctrls.fh" :edicion=true style="flex: 0 0 28%" />
              
              <v-btn style="margin-left:5px;" v-bind="$cfg.btn.busca" @click="event_search('buscarApd')">
                <v-icon dark>{{ $cfg.btn.busca.icono }}</v-icon>
              </v-btn>               
            </div>

            <div class="conflex">
              <v-checkbox                   
                style="flex: 0 0 50%"            
                v-bind="$checkbox"
                v-model="schema.ctrls.guardia.value"
                :label="schema.ctrls.guardia.label">                
              </v-checkbox>
            </div>
          </div>
        </div>


        <!-- Grid -->
        <base_Fgrid 
          :padre="stName" 
          :Entorno="Entorno" 
          @onEvent="event_Grid"
          hide-default-footer>
            
        </base_Fgrid>
      </div>
    </v-sheet>
  </div>
</template>


<script>

  import { mixinFinder } from "@/mixins/mixinFinder.js";
  import plugs from "@/common/general_plugs";
  const base_Header = () => plugs.groute("base_Header.vue", "base"); 
  const base_Fgrid = () => plugs.groute("base_Fgrid_maub.vue", "base");  
  const compfecha = () => plugs.groute("compfecha.vue", "comp");
  const ctrlfinder = () => plugs.groute("ctrlfinder.vue", "comp");  

  export default {
    mixins: [mixinFinder],
    components: { base_Header, base_Fgrid, compfecha, ctrlfinder },
    props: {
      padre: { type:String, default: ''},
      componenteTipo: { type:String, default:'FM' },
      Entorno: { type: Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.F)); }}
    },


    data() {
      return {
        api:'',
        stName:'stFbusqueda_Apd',
        component_M:null,
        modal:false,
        accion:'',
        accionRow: {},        
        disparo:false,
        schema:{
          ctrls: {
            cia: { f:'cia', label:'Cia', value:'1000' },
            origen: { f:'origen', label:'Municipio', value:'0',
              comp: {
                type:"pick",
                api:"generic",						
                source:"foxatlas",
                f_aux:"name",					
                valueaux:"", 
                finder:'muni_F' 
              }  
            },
            cpo: { f:'cpo', label:'Cod. Postal', value:'' },
            guardia: { f:'guardia', label:'Todos, incluir fuera de guardia', value:'' },
            fh: { f:'fh', label:'Dia y Hora', value:'',
              comp: {
                type:'fecha',
                format:'fhhr'
              }
            },
          }
        },

        headers: {}, 
        itemsCias:[],      
        
      };
    },


    created() {
      this.ini_data();
    },


    methods: {
      ini_data() {
        console.log("DEV " + this.api + " ********************** (ini_data)");
             
        // configuro Entorno 
        this.Entorno.header.titulo = "Búsqueda de APDS";  

        this.headers = {
          header: [
            { text: "Estado", value: "std", witdh: "10rem" },
            { text: "Nombre", value: "name", witdh: "10rem" },
            { text: "Código", value: "codigo", witdh: "10rem" },
            { text: "Teléfono", value: "tlf1", witdh: "10rem" },
            { text: "Móvil", value: "mov1", witdh: "10rem" },
            { text: "Agencia", value: "afecta", witdh: "10rem" },            
            { text: "Último Exp.", value: "fhhr", witdh: "10rem" },
            { text: "Exp.", value: "exped", witdh: "10rem" }            
          ],

        };

        this.Entorno.grid.headers = this.set_headers(); 
        
        // items adicionales
        // array de compañías
        this.itemsCias= [      
          { id:'1000', label:'Mapfre' },
          { id:'999', label:'Otras Cias'}
        ]
      },  

      
      // asigno código postal del origen extraido
      extraid_Cpo(r) {        
        this.schema.ctrls.cpo.value= r.cp;
      },


      // gestor de eventos
      async event_search() {
        var ctrls= this.schema.ctrls;

        //
        var args= { tipo:'fnc', accion: 'apds', fn_args: { accion:'agenda_busqueda',   
          clave: ctrls.cia.value,       
          cpo: ctrls.cpo.value,
          fh: ctrls.fh.value,
          todos: ctrls.guardia.value}
        };

        var apiResult= await this.buscarExecFnc(args);
        if (apiResult.sql.error) return;
       
      },


      //
      event_Header() {
        this.$emit('onEvent', {accion:'cerrar'});
      },


    }
  };
</script>
